const CONSTS = {
  APP_NAME: "HEkishoku",
  DEFAULT_WORK_FROM: "1984-01-01T09:00:00",
  DEFAULT_WORK_TO: "1984-01-01T18:00:00",
  DEFAULT_LUNCH_BREAK_FROM: "1984-01-01T12:00:00",
  DEFAULT_LUNCH_BREAK_HOURS: 1,
  DEFAULT_MINUTES_STEP: 15,
  CREATED: "CREATED",
  DONE: "DONE",
  STARTED_AT: "startedAt",
  ENDED_BY: "endedBy",
  STARTED_BY: "startedBy",
  ENDED_AT: "endedAt",
  BOX_M: 1,
  ICON_HEIGHT: 24,
  DATE_TIME_FORMAT: "H:mm E MMM d y",
};

export default CONSTS;
